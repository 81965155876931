<template>
  <div class="novelDetailsPage oepnX">
    <PullRefresh
      v-if="!isLoading"
      :disabled="true"
      :is-higeht-max="true"
      :adv-list="advList"
      :hasAdvPagination="true"
      className="NovelDetailsPage"
    >
      <div class="content" v-if="!isLoading && atlasData">
        <!--        <img class="headBg" src="@/assets/png/novelBg.png" />-->
        <ImgDecypt
          class="headBg"
          :src="atlasData.verticalCover"
          :key="atlasData.verticalCover"
        ></ImgDecypt>
        <div class="head">
          <!--               <img class="headBg" src="@/assets/png/novelBg.jpg" />-->
          <div class="headBar">
            <div class="leftIcon" @click="$router.go(-1)">
              <img src="@/assets/png/leftblack.png" />
            </div>
            <!-- 收藏 -->
            <div class="btnGroup">
              <div
                class="collectionBtn"
                v-if="atlasData && !atlasData.hasCollected"
                @click="collectionWorks"
              >
                <img src="@/assets/png/isnovelCollect.png" />
              </div>
              <div
                class="collectionBtn"
                v-else-if="atlasData"
                @click="cancelCollectionWorks"
              >
                <img src="@/assets/png/hasnovelCollect.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="worksInfo">
          <div class="contentInfo">
            <!--            <div class="previewImg">-->
            <!--              <ImgDecypt-->
            <!--                :src="atlasData.verticalCover"-->
            <!--                :key="atlasData.verticalCover"-->
            <!--              ></ImgDecypt>-->
            <!--            </div>-->
            <div class="worksContent">
              <div class="worksTitle ellipsis">
                <span>{{ atlasData.title }}</span>
              </div>
              <div class="worksTag">
                <span
                  v-for="(item, index) in atlasData.tagNames"
                  :key="index"
                  @click.stop="tagJump(index, item)"
                >
                  {{ `# ` + item }}
                </span>
              </div>
              <div class="dataNum">
                <div>{{ atlasData.countBrowse | watchCount }}观看</div>
                <div>{{ atlasData.countComment | watchCount }}评论</div>
                <div>{{ atlasData.countCollect | watchCount }}收藏</div>
              </div>
              <div class="newVip" v-if="countdownSec">
                <div class="Diamond">
                  <img src="@/assets/png/Diamond.png" alt="" />
                </div>
                <div class="newUserText">新用户升级会员特惠</div>
                <div>{{ timeStr || `00:00:00` }}</div>
                <div class="immediately">
                  <router-link
                    tag="div"
                    to="/rechargePage?t=vip"
                    class="openVip"
                  >
                    <img src="@/assets/png/immediately.png" alt="" />
                  </router-link>
                </div>
              </div>
              <div class="worksDetails">
                <span>{{ atlasData.summary || "暂无简介" }}</span>
              </div>

              <!-- <TextOverflow
                class="worksDetails"
                :text="atlasData.summary"
                :maxLines="2.4"
              >
                <template
                  v-slot:default="{ clickToggle, expanded }"
                  v-if="kind == 4 || kind == 1"
                >
                  <div @click.stop="clickToggle" class="text-btn">
                    {{ expanded ? "收起" : "全文" }}
                  </div>
                </template>
              </TextOverflow> -->

              <div class="worksFullBtn" v-if="atlasData.summary.length > 58">
                <div class="showAll">
                  <span @click="introductionShow = true">全文</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lines"></div>
        <div class="contentBar">
          <van-tabs
            class="tabs"
            animated
            background="#FFFFFF"
            color="#666666"
            title-inactive-color="#666666"
            title-active-color="#333333"
            v-model="activeTab"
            v-if="randomList.length || commentList.length"
          >
            <van-tab title="推荐" class="tabContent">
              <PullRefresh
                v-if="!isLoading"
                :disabled="true"
                :is-higeht-max="true"
                :adv-list="advList"
                :hasAdvPagination="true"
                className="NovelDetailsPage"
              >
                <div class="videoContent" v-if="randomList">
                  <!-- 同类作品 -->
                  <div class="recommendVideo">
                    <!--              <div class="recommendTitle">-->
                    <!--               <span>大家正在看</span>-->
                    <!--              </div>-->
                    <div>
                      <VerticleThreeList
                        :videoList="randomList"
                      ></VerticleThreeList>
                    </div>
                  </div>
                </div>
                <!--            <div style="width: 100%; height: 300px"></div>-->
              </PullRefresh>
              <Loading v-else />
            </van-tab>
            <van-tab class="tabContent oepnX">
              <template #title>
                <div class="tabItemTitle">
                  <div>互动点评</div>
                  <div class="comTotle">{{ msgTotle | watchCount }}</div>
                </div>
              </template>
              <!--  添加没有评论框    :isNoData="isNoData"-->
              <PullRefresh
                v-if="!isLoading"
                :loading="loading"
                :refreshing="refreshing"
                :finished="finished"
                @onLoad="onLoad"
                @onRefresh="onRefresh"
                :error="error"
                :is-higeht-max="true"
                :adv-list="advList"
                :hasAdvPagination="true"
                className="NovelDetailsPage"
              >
                <div class="commentsContent">
                  <div
                    class="commentsContentList"
                    v-if="commentList.length > 0"
                  >
                    <div
                      class="commentBox"
                      v-for="item in commentList"
                      :key="item.id"
                    >
                      <div class="commentInfo">
                        <ImgDecypt
                          class="avatar"
                          round
                          :src="item.userPortrait"
                          :key="item.userPortrait"
                        />
                        <div class="info">
                          <div class="name">
                            <span>{{ item.userName }}</span>
                            <svg-icon
                              :iconClass="
                                item.gender == 'male' ? 'maleMsg' : 'female'
                              "
                            ></svg-icon>
                          </div>
                          <div class="text msgContent" v-if="!item.isDelete">
                            <div style="width: calc(100% - 22px)">
                                <CommentJump
                                    class="commit_content"
                                    :text="item.content"
                                    :keyword="item.searchKeyword"
                                    :linkType="item.linkType"
                                    :linkStr="item.linkStr"
                                />
                            </div>
                          </div>
                          <div
                            class="text"
                            style="color: rgb(153, 153, 153)"
                            v-else
                          >
                            内容已被删除
                          </div>
                          <div class="address" v-if="!item.isTop">
                            <div class="createdAt">
                              <span>{{ item.createdAt | time }}</span>
                            </div>

                            <div class="btnBox" v-if="!item.linkType">
                              <span @click.stop="reply(item, null)">
                                <svg-icon
                                  class="community_info"
                                  iconClass="community_info"
                                />
                              </span>

                              <span @click.stop="likeComment(item)">
                                <svg-icon
                                  class="like icon"
                                  v-if="item.isLike"
                                  iconClass="postLikeedIcon"
                                />
                                <svg-icon
                                  class="like icon"
                                  v-else
                                  iconClass="postLikeIcon"
                                />
                              </span>
                            </div>
                          </div>
                          <div v-else>
                            <span
                              style="color: rgb(153, 153, 153); font-size: 12px"
                              >------此評論系統生成 無法回复</span
                            >
                          </div>

                          <div class="commentBox" style="border-bottom: none">
                            <div
                              class="commentInfo"
                              v-if="item.Info && item.Info.length > 0"
                              style="margin-bottom: 10px"
                            >
                              <ImgDecypt
                                class="avatar"
                                round
                                :src="item.Info[0].userPortrait"
                                :key="item.Info[0].userPortrait"
                              />
                              <div class="childInfo">
                                <div class="name">
                                  <span>{{ item.Info[0].userName }}</span>
                                  <svg-icon
                                    :iconClass="
                                      item.Info[0].gender == 'male'
                                        ? 'male'
                                        : 'female'
                                    "
                                  ></svg-icon>
                                  <svg-icon
                                    v-if="
                                      item.Info[0].toUserID != 0 &&
                                      item.Info[0].toUserID != null
                                    "
                                    class="rightCommIcon"
                                    iconClass="rightCommIcon"
                                  ></svg-icon>
                                  <span v-if="item.Info[0].toUserID">{{
                                    item.Info[0].toUserName
                                  }}</span>
                                </div>
                                <div
                                  class="text msgContent"
                                  v-if="!item.Info[0].isDelete"
                                >
                                  <div style="width: calc(100% - 22px)">
                                      <CommentJump
                                          class="commit_content"
                                          :text="item.Info[0].content"
                                          :keyword="item.Info[0].searchKeyword"
                                          :linkType="item.Info[0].linkType"
                                          :linkStr="item.Info[0].linkStr"
                                      />
                                  </div>
                                </div>
                                <div
                                  class="text"
                                  style="color: rgb(153, 153, 153)"
                                  v-else
                                >
                                  内容已被删除
                                </div>
                                <div class="address">
                                  <div class="createdAt">
                                    <span>{{
                                      item.Info[0].createdAt | time
                                    }}</span>
                                  </div>

                                  <div class="btnBox" v-if="!item.Info[0].linkType">
                                    <span
                                      @click.stop="reply(item.Info[0], item)"
                                    >
                                      <svg-icon
                                        class="community_info"
                                        iconClass="community_info"
                                      />
                                    </span>

                                    <span
                                      @click.stop="likeComment(item.Info[0])"
                                    >
                                      <svg-icon
                                        class="like icon"
                                        v-if="item.Info[0].isLike"
                                        iconClass="postLikeedIcon"
                                      />
                                      <svg-icon
                                        class="like icon"
                                        v-else
                                        iconClass="postLikeIcon"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="item.isShow">
                              <div
                                class="commentInfo"
                                v-for="subComment in item.Info.slice(1)"
                                :key="subComment.id"
                                style="margin-bottom: 10px"
                              >
                                <ImgDecypt
                                  class="avatar"
                                  round
                                  :src="subComment.userPortrait"
                                  :key="item.userPortrait"
                                />
                                <div class="childInfo">
                                  <div class="name">
                                    <span>{{ subComment.userName }}</span>
                                    <svg-icon
                                      :iconClass="
                                        subComment.gender == 'male'
                                          ? 'male'
                                          : 'female'
                                      "
                                    ></svg-icon>
                                    <svg-icon
                                      v-if="
                                        subComment.toUserID != 0 &&
                                        subComment.toUserID != null
                                      "
                                      class="rightCommIcon"
                                      iconClass="rightCommIcon"
                                    ></svg-icon>
                                    <span
                                      v-if="
                                        subComment.toUserID != 0 &&
                                        subComment.toUserID != null
                                      "
                                      >{{ subComment.toUserName }}</span
                                    >
                                  </div>
                                  <div
                                    class="text msgContent"
                                    v-if="!subComment.isDelete"
                                  >
                                    <div style="width: calc(100% - 22px)">
                                        <CommentJump
                                            class="commit_content"
                                            :text="subComment.content"
                                            :keyword="subComment.searchKeyword"
                                            :linkType="subComment.linkType"
                                            :linkStr="subComment.linkStr"
                                        />
                                    </div>
                                  </div>
                                  <div
                                    class="text"
                                    style="color: rgb(153, 153, 153)"
                                    v-else
                                  >
                                    内容已被删除
                                  </div>
                                  <div class="address">
                                    <div class="createdAt">
                                      <span>{{
                                        subComment.createdAt | time
                                      }}</span>
                                    </div>

                                    <div class="btnBox" v-if="!subComment.linkType">
                                      <span
                                        @click.stop="reply(subComment, item)"
                                      >
                                        <svg-icon
                                          class="community_info"
                                          iconClass="community_info"
                                        />
                                      </span>

                                      <span
                                        @click.stop="likeComment(subComment)"
                                      >
                                        <svg-icon
                                          class="like icon"
                                          v-if="subComment.isLike"
                                          iconClass="postLikeedIcon"
                                        />
                                        <svg-icon
                                          class="like icon"
                                          v-else
                                          iconClass="postLikeIcon"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <Loading
                              class="sec-loading"
                              v-if="
                                commentParams.currentFirstItem.id == item.id &&
                                commentParams.currentLoading
                              "
                            />
                            <div
                              class="load-more"
                              v-else-if="
                                !commentParams.currentLoading &&
                                commentParams.currentFirstItem.id == item.id &&
                                commentParams.currentHasNext
                              "
                              @click.stop="loadMoreSubComment(item)"
                            >
                              — 点击加载更多评论
                            </div>
                          </div>
                        </div>
                        <img
                          class="godMsg"
                          v-if="item.isGodComment"
                          src="@/assets/png/godMsg.png"
                        />
                      </div>
                      <div
                        class="expand-box"
                        @click.stop="onShow(item)"
                        v-if="item.commCount && item.commCount > 1"
                      >
                        {{
                          item.isShow
                            ? "— 收起"
                            : `— 展开${item.commCount - 1}条回复`
                        }}
                        <svg-icon
                          iconClass="expand"
                          :class="[
                            { 'rotate-target': item.isShow },
                            { 'rotate-burden-target': !item.isShow },
                          ]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 100%; height: 350px"></div>
              </PullRefresh>
              <Loading v-else />
            </van-tab>
          </van-tabs>
        </div>

        <div v-if="activeTab === 1" class="defaultInput">
          <div class="inputBox">
            <van-field
              ref="commentInputBoxRef"
              class="newInput"
              v-model="commentParams.content"
              :border="false"
              :placeholder="placeholder"
              @blur="changBlur"
            />
            <div
              class="sendBtn"
              :class="{ activeBtn: msgVal }"
              @mousedown="handleMousedown"
            ></div>
          </div>
        </div>

        <div v-if="activeTab === 0" class="directory">
          <!--        <div class="directoryBar">-->
          <!--         <div class="directoryBarTitle">-->
          <!--          <span>选集</span>-->
          <!--         </div>-->
          <!--         <div class="directoryBtn" @click="directoryShow = true">-->
          <!--          <span v-if="kind == 4">全{{ atlasData.contents.length }}章</span>-->
          <!--          <span v-else>全{{ atlasData.contents.length }}集</span>-->
          <!--          &lt;!&ndash;                       <img src="@/assets/png/rightDown.png">&ndash;&gt;-->
          <!--         </div>-->
          <!--        </div>-->
          <div class="directory-left" @click="directoryShow = true">
            <svg-icon icon-class="catalogue" class="catalogue"></svg-icon>
            <div>目录</div>
          </div>
          <div class="anthology oepnX" id="anthologyID">
            <div
              class="anthologyItem"
              :class="`anthologyItem-${index}`"
              v-for="(item, index) in atlasData.contents"
              @click="anthology(item, index)"
              :ref="`item-${index}`"
              :key="index"
            >
              <div>
                <!-- vip -->
                <div class="vipLabel" v-if="item.listenPermission == 0">
                  <span>VIP</span>
                </div>

                <!-- 金豆 -->
                <div
                  class="goldenLabel"
                  v-if="item.listenPermission == 1 && price > 0"
                >
                  <span>金币</span>
                </div>

                <!-- 免费 -->
                <div class="freeLabel" v-if="item.listenPermission == 2">
                  <span>免费</span>
                </div>

                <div
                  class="anthologyTxt"
                  :class="{ activeItem: activeIndex == index }"
                >
                  <span>
                    {{ index + 1 }}
                  </span>
                </div>
                <!-- <div class="activePlay" v-if="activeIndex == index">
                               <img src="@/assets/gif/activePlay.gif">
                           </div> -->
              </div>
            </div>
          </div>
          <div
            class="startReading"
            @click="anthology(atlasData.contents[0], 0)"
            v-if="activeIndex == 0"
          >
            <div class="startReadingBtn">
              <span v-if="kind == 4">阅读小说</span>
              <span v-else>观看漫画</span>
            </div>
          </div>
          <div
            class="startReading"
            @click="anthology(atlasData.contents[activeIndex], activeIndex)"
            v-else
          >
            <div class="startReadingBtn">
              <span v-if="kind == 4">续看第 {{ activeIndex + 1 }} 章</span>
              <span v-else>续看第 {{ activeIndex + 1 }} 集</span>
            </div>
          </div>
          <!--          <div class="lines"></div>-->
        </div>

        <!--        <div class="separate"></div>-->

        <!--        <div class="comments">-->
        <!--          <div class="commentsContent">-->
        <!--            <div class="commentsHead">-->
        <!--              <span>评论</span>-->
        <!--              <span class="comTotle">{{ msgTotle }}</span>-->
        <!--            </div>-->
        <!--            <div class="commentsContentList" v-if="commentList.length > 0">-->
        <!--              <div-->
        <!--                class="commentBox"-->
        <!--                v-for="item in commentList"-->
        <!--                :key="item.id"-->
        <!--              >-->
        <!--                <div class="commentInfo">-->
        <!--                  <ImgDecypt-->
        <!--                    class="avatar"-->
        <!--                    round-->
        <!--                    :src="item.userPortrait"-->
        <!--                    :key="item.userPortrait"-->
        <!--                  />-->
        <!--                  <div class="info">-->
        <!--                    <div class="name">-->
        <!--                      <span>{{ item.userName }}</span>-->
        <!--                      <svg-icon-->
        <!--                        :iconClass="-->
        <!--                          item.gender == 'male' ? 'maleMsg' : 'female'-->
        <!--                        "-->
        <!--                      ></svg-icon>-->
        <!--                    </div>-->
        <!--                    <div class="text msgContent" v-if="!item.isDelete">-->
        <!--                      <div style="width: calc(100% - 22px)">-->
        <!--                        {{ item.content }}-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div class="text" style="color: rgb(153, 153, 153)" v-else>-->
        <!--                      内容已被删除-->
        <!--                    </div>-->
        <!--                    <div class="address" v-if="!item.isTop">-->
        <!--                      <div class="createdAt">-->
        <!--                        <span>{{ item.createdAt | time }}</span>-->
        <!--                      </div>-->

        <!--                      <div class="btnBox">-->
        <!--                        <span @click.stop="reply(item, null)">-->
        <!--                          <svg-icon-->
        <!--                            class="community_info"-->
        <!--                            iconClass="community_info"-->
        <!--                          />-->
        <!--                        </span>-->

        <!--                        <span @click.stop="likeComment(item)">-->
        <!--                          <svg-icon-->
        <!--                            class="like icon"-->
        <!--                            v-if="item.isLike"-->
        <!--                            iconClass="postLikeedIcon"-->
        <!--                          />-->
        <!--                          <svg-icon-->
        <!--                            class="like icon"-->
        <!--                            v-else-->
        <!--                            iconClass="postLikeIcon"-->
        <!--                          />-->
        <!--                        </span>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div v-else>-->
        <!--                      <span style="color: rgb(153, 153, 153); font-size: 12px"-->
        <!--                        >&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;此评论系统生成 无法回复</span-->
        <!--                      >-->
        <!--                    </div>-->

        <!--                    <div class="commentBox" style="border-bottom: none">-->
        <!--                      <div-->
        <!--                        class="commentInfo"-->
        <!--                        v-if="item.Info && item.Info.length > 0"-->
        <!--                        style="margin-bottom: 10px"-->
        <!--                      >-->
        <!--                        <ImgDecypt-->
        <!--                          class="avatar"-->
        <!--                          round-->
        <!--                          :src="item.Info[0].userPortrait"-->
        <!--                          :key="item.Info[0].userPortrait"-->
        <!--                        />-->
        <!--                        <div class="childInfo">-->
        <!--                          <div class="name">-->
        <!--                            <span>{{ item.Info[0].userName }}</span>-->
        <!--                            <svg-icon-->
        <!--                              :iconClass="-->
        <!--                                item.Info[0].gender == 'male'-->
        <!--                                  ? 'male'-->
        <!--                                  : 'female'-->
        <!--                              "-->
        <!--                            ></svg-icon>-->
        <!--                            <svg-icon-->
        <!--                              v-if="-->
        <!--                                item.Info[0].toUserID != 0 &&-->
        <!--                                item.Info[0].toUserID != null-->
        <!--                              "-->
        <!--                              class="rightCommIcon"-->
        <!--                              iconClass="rightCommIcon"-->
        <!--                            ></svg-icon>-->
        <!--                            <span v-if="item.Info[0].toUserID">{{-->
        <!--                              item.Info[0].toUserName-->
        <!--                            }}</span>-->
        <!--                          </div>-->
        <!--                          <div-->
        <!--                            class="text msgContent"-->
        <!--                            v-if="!item.Info[0].isDelete"-->
        <!--                          >-->
        <!--                            <div style="width: calc(100% - 22px)">-->
        <!--                              {{ item.Info[0].content }}-->
        <!--                            </div>-->
        <!--                          </div>-->
        <!--                          <div-->
        <!--                            class="text"-->
        <!--                            style="color: rgb(153, 153, 153)"-->
        <!--                            v-else-->
        <!--                          >-->
        <!--                            内容已被删除-->
        <!--                          </div>-->
        <!--                          <div class="address">-->
        <!--                            <div class="createdAt">-->
        <!--                              <span>{{ item.Info[0].createdAt | time }}</span>-->
        <!--                            </div>-->

        <!--                            <div class="btnBox">-->
        <!--                              <span @click.stop="reply(item.Info[0], item)">-->
        <!--                                <svg-icon-->
        <!--                                  class="community_info"-->
        <!--                                  iconClass="community_info"-->
        <!--                                />-->
        <!--                              </span>-->

        <!--                              <span @click.stop="likeComment(item.Info[0])">-->
        <!--                                <svg-icon-->
        <!--                                  class="like icon"-->
        <!--                                  v-if="item.Info[0].isLike"-->
        <!--                                  iconClass="postLikeedIcon"-->
        <!--                                />-->
        <!--                                <svg-icon-->
        <!--                                  class="like icon"-->
        <!--                                  v-else-->
        <!--                                  iconClass="postLikeIcon"-->
        <!--                                />-->
        <!--                              </span>-->
        <!--                            </div>-->
        <!--                          </div>-->
        <!--                        </div>-->
        <!--                      </div>-->
        <!--                      <div v-if="item.isShow">-->
        <!--                        <div-->
        <!--                          class="commentInfo"-->
        <!--                          v-for="subComment in item.Info.slice(1)"-->
        <!--                          :key="subComment.id"-->
        <!--                          style="margin-bottom: 10px"-->
        <!--                        >-->
        <!--                          <ImgDecypt-->
        <!--                            class="avatar"-->
        <!--                            round-->
        <!--                            :src="subComment.userPortrait"-->
        <!--                            :key="item.userPortrait"-->
        <!--                          />-->
        <!--                          <div class="childInfo">-->
        <!--                            <div class="name">-->
        <!--                              <span>{{ subComment.userName }}</span>-->
        <!--                              <svg-icon-->
        <!--                                :iconClass="-->
        <!--                                  subComment.gender == 'male'-->
        <!--                                    ? 'male'-->
        <!--                                    : 'female'-->
        <!--                                "-->
        <!--                              ></svg-icon>-->
        <!--                              <svg-icon-->
        <!--                                v-if="-->
        <!--                                  subComment.toUserID != 0 &&-->
        <!--                                  subComment.toUserID != null-->
        <!--                                "-->
        <!--                                class="rightCommIcon"-->
        <!--                                iconClass="rightCommIcon"-->
        <!--                              ></svg-icon>-->
        <!--                              <span-->
        <!--                                v-if="-->
        <!--                                  subComment.toUserID != 0 &&-->
        <!--                                  subComment.toUserID != null-->
        <!--                                "-->
        <!--                                >{{ subComment.toUserName }}</span-->
        <!--                              >-->
        <!--                            </div>-->
        <!--                            <div-->
        <!--                              class="text msgContent"-->
        <!--                              v-if="!subComment.isDelete"-->
        <!--                            >-->
        <!--                              <div style="width: calc(100% - 22px)">-->
        <!--                                {{ subComment.content }}-->
        <!--                              </div>-->
        <!--                            </div>-->
        <!--                            <div-->
        <!--                              class="text"-->
        <!--                              style="color: rgb(153, 153, 153)"-->
        <!--                              v-else-->
        <!--                            >-->
        <!--                              内容已被删除-->
        <!--                            </div>-->
        <!--                            <div class="address">-->
        <!--                              <div class="createdAt">-->
        <!--                                <span>{{ subComment.createdAt | time }}</span>-->
        <!--                              </div>-->

        <!--                              <div class="btnBox">-->
        <!--                                <span @click.stop="reply(subComment, item)">-->
        <!--                                  <svg-icon-->
        <!--                                    class="community_info"-->
        <!--                                    iconClass="community_info"-->
        <!--                                  />-->
        <!--                                </span>-->

        <!--                                <span @click.stop="likeComment(subComment)">-->
        <!--                                  <svg-icon-->
        <!--                                    class="like icon"-->
        <!--                                    v-if="subComment.isLike"-->
        <!--                                    iconClass="postLikeedIcon"-->
        <!--                                  />-->
        <!--                                  <svg-icon-->
        <!--                                    class="like icon"-->
        <!--                                    v-else-->
        <!--                                    iconClass="postLikeIcon"-->
        <!--                                  />-->
        <!--                                </span>-->
        <!--                              </div>-->
        <!--                            </div>-->
        <!--                          </div>-->
        <!--                        </div>-->
        <!--                      </div>-->

        <!--                      <Loading-->
        <!--                        class="sec-loading"-->
        <!--                        v-if="-->
        <!--                          commentParams.currentFirstItem.id == item.id &&-->
        <!--                          commentParams.currentLoading-->
        <!--                        "-->
        <!--                      />-->
        <!--                      <div-->
        <!--                        class="load-more"-->
        <!--                        v-else-if="-->
        <!--                          !commentParams.currentLoading &&-->
        <!--                          commentParams.currentFirstItem.id == item.id &&-->
        <!--                          commentParams.currentHasNext-->
        <!--                        "-->
        <!--                        @click.stop="loadMoreSubComment(item)"-->
        <!--                      >-->
        <!--                        — 点击加载更多评论-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <img-->
        <!--                    class="godMsg"-->
        <!--                    v-if="item.isGodComment"-->
        <!--                    src="@/assets/png/godMsg.png"-->
        <!--                  />-->
        <!--                </div>-->
        <!--                <div-->
        <!--                  class="expand-box"-->
        <!--                  @click.stop="onShow(item)"-->
        <!--                  v-if="item.commCount && item.commCount > 1"-->
        <!--                >-->
        <!--                  {{-->
        <!--                    item.isShow ? "— 收起" : `— 展开${item.commCount - 1}条回复`-->
        <!--                  }}-->
        <!--                  <svg-icon-->
        <!--                    iconClass="expand"-->
        <!--                    :class="[-->
        <!--                      { 'rotate-target': item.isShow },-->
        <!--                      { 'rotate-burden-target': !item.isShow },-->
        <!--                    ]"-->
        <!--                  />-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="nodata" v-else>空空如也～</div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </PullRefresh>
    <Loading v-else />

    <!-- <div class="tabBar" @click="showInput">
      <div class="comments">
        <span>发条有爱的评论吧</span>
        <img src="@/assets/png/commentsEditor.png" />
      </div>
      <div class="placeholder"></div>
    </div> -->
    <!--    <div class="tabBars">-->
    <!--      <div class="comments">-->
    <!--        <van-field-->
    <!--          class="Inputs"-->
    <!--          ref="commentInputBoxRef"-->
    <!--          v-model="commentParams.content"-->
    <!--          :border="false"-->
    <!--          :placeholder="placeholder"-->
    <!--          @blur="changBlur"-->
    <!--        />-->
    <!--        <div-->
    <!--          class="sendBtn"-->
    <!--          :class="{ activeBtn: msgVal }"-->
    <!--          @mousedown="handleMousedown"-->
    <!--        >-->
    <!--          <img src="@/assets/png/commentsEditor.png" />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- 简介弹窗 -->
    <van-popup
      v-model="introductionShow"
      position="bottom"
      :style="{ height: '529px', background: 'rgb(255,255,255)' }"
    >
      <div class="introductionContent" v-if="atlasData">
        <div class="introductionBar">
          <div class="title">
            <span>简介</span>
          </div>
          <div class="btn" @click="introductionShow = false">
            <img src="@/assets/png/popupClose.png" />
          </div>
        </div>
        <div class="introductionTitle">
          <span>{{ atlasData.title }}</span>
        </div>
        <div class="introductionTag">
          <span v-for="(item, index) in atlasData.tagNames" :key="index">
            {{ item }}
          </span>
        </div>
        <div class="introductionDetails">
          <span>{{ atlasData.summary }}</span>
        </div>
      </div>
    </van-popup>

    <!-- 目录弹窗 -->
    <van-popup
      v-model="directoryShow"
      position="bottom"
      :style="{ height: '511px', background: 'rgb(255,255,255)' }"
    >
      <div class="directoryContent" v-if="atlasData">
        <div class="directoryBar">
          <div class="title">
            <span v-if="kind == 4"
              >目录（全{{ atlasData.contents2.length }}章）</span
            >
            <span v-if="kind == 1"
              >目录（全{{ atlasData.contents2.length }}话）</span
            >
          </div>
          <div class="btn" @click="chapterSort">
            <span v-if="sorting">倒序</span>
            <span v-else>升序</span>
          </div>
          <!-- <div class="btn" @click="directoryShow = false">
            <img src="@/assets/png/popupClose.png" />
          </div> -->
        </div>
        <div class="anthologyContent">
          <div
            class="anthologyItem"
            v-for="(item, index) in atlasData.contents2"
            @click="
              anthology(
                item,
                sorting ? index : atlasData.contents2.length - index - 1
              )
            "
            :key="index"
          >
            <div>
              <!-- vip -->
              <div class="vipLabel" v-if="item.listenPermission == 0">
                <span>VIP</span>
              </div>

              <!-- 金豆 -->
              <div class="goldenLabel" v-if="item.listenPermission == 1">
                <span>{{ item.price }}金币</span>
              </div>

              <!-- 免费 -->
              <div class="freeLabel" v-if="item.listenPermission == 2">
                <span>免费</span>
              </div>

              <div
                class="anthologyTxt"
                :class="{ activeItem: activeIndex2 == index }"
              >
                <span>
                  {{ item.episodeNumber }}
                </span>
              </div>
              <!-- <div class="activePlay" v-if="activeIndex == index">
                                <img src="@/assets/gif/activePlay.gif">
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import TextOverflow from "@/components/TextOverflow";
import { queryTiroCountdown } from "@/api/mine";
import CommentJump from "@/components/CommentJump";

import { Toast } from "vant";
import {
  sendComment,
  sendV2Comment,
  thumbsDownComment,
  thumbsUpComment,
} from "@/api/mine";
import {
  doCollect,
  douUcollect,
  queryMediaInfoList,
  queryRandomInfoList,
} from "@/api/video";
import ImgDecypt from "@/components/ImgDecypt";
import Loading from "@/components/Loading";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import PullRefresh from "@/components/PullRefresh";
import { setSessionItem } from "@/utils/longStorage";
import { addVideoHistory, queryHistoryId } from "@/utils";
import { getBeijinTime } from "@/utils";
import { queryCommentListlever2 } from "@/api/user";

import store from "@/store";
import { queryCommentList } from "@/api/user";
export default {
  name: "NovelDetailsPage",
  components: {
    ImgDecypt,
    Loading,
    VerticleThreeList,
    PullRefresh,
    CommentJump,
    // TextOverflow
  },
  data() {
    return {
      activeTab: 0,
      timeStr: "",
      countdownSec: 0, // 新手倒计时时间 单位秒
      countdownFncs: "",
      maxEpisode: 0, // 用于存储最大集数
      mediaID: null,
      kind: null,
      atlasData: null,
      introductionShow: false,
      directoryShow: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      randomList: [],
      finished: false,
      isNoData: false,
      error: false,
      advList: [],
      activeIndex: 0,
      activeIndex2: 0,
      commentList: [],
      pageNumber: 1,
      pageSize: 10,
      isShowInput: false,
      msgVal: "",
      placeholder: "我怀疑你也想开车",
      cid: undefined,
      userId: undefined,
      level: 1,
      msgTotle: 0,
      price: 0,
      sorting: true,
      currMsgItem: "",
      commentParams: {
        objID: "", // 帖子ID,
        level: 1, // 评论级别，1: 一级评论;2: 二级评论
        content: "", // 评论内容
        cid: "", // 一级评论的ID
        userID: "", // 一级评论的用户ID
        currentFirstItem: {}, // 当前一级评论对象
        currentHasNext: false, // 当前评论是否还有下一页数据
        currentLoading: false, // 当前二级评论的loading
        currentShowInputId: "", // 当前显示的输入框id
        rid: "",
        toUserID: "",
      }, // 评论参数
      secPageNumber: 1, // 二级评论页码
      secPageSize: 10, // 二级评论一页的数量
    };
  },
  // mounted() {
  //   let query = this.$route.query;
  //   if (query) {
  //     this.mediaID = query.id;
  //     this.kind = query.kind;
  //     this.price = query.price ? Number(query.price) : 0;
  //     this.activeIndex = 0;
  //     this.activeIndex2 = 0;
  //     this.isLoading = true;
  //     this.getMediaInfoList();
  //     this.getRandomInfoList();
  //     this.getCommentList();
  //   }

  // },
  activated() {
    const { refreshFlag } = store.state.pageRefresh;
    let query = this.$route.query;
    if (query && refreshFlag.novelDetailsPage) {
      this.resetData(query);
    }
    if (!refreshFlag.novelDetailsPage) {
      this.setActiveIndex();
    }
  },
  deactivated() {
    store.commit("pageRefresh/SET_REFRESHFLAG", {
      name: "novelDetailsPage",
      flag: false,
    });
    this.atlasData.historyIndex = this.activeIndex;
    addVideoHistory(this.atlasData);
  },
  beforeDestroy() {
    clearTimeout(this.countdownFncs);
  },
  created() {
    this.queryTiroCountdown();
  },
  watch: {
    $route(to, from) {
      const { id } = to.query;
      const novelDetailsPageId = sessionStorage.getItem("novelDetailsPageId");
      if (id && novelDetailsPageId && id !== novelDetailsPageId) {
        this.resetData(to.query);
      }
    },
  },
  methods: {
    // 查询新手优惠时间
    async queryTiroCountdown() {
      let res = await this.$Api(queryTiroCountdown);
      const { data } = res;
      // console.log(res);
      if (res && res.code === 200) {
        this.countdownSec = data.countdownSec;
        this.countdown(this.countdownSec);
      }
    },
    // 倒计时
    countdown(timer) {
      if (timer <= 0) return;
      // 相差的毫秒数
      const msec = timer;
      // 计算时分秒数
      let hr = parseInt(msec / 60 / 60);
      let min = parseInt((msec / 60) % 60);
      let sec = parseInt(msec % 60);
      // 个位数前补零
      hr = hr > 9 ? hr : "0" + hr;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      this.timeStr = `${hr}:${min}:${sec}`;
      // 控制台打印
      // console.log(` ${hr}小时 ${min}分钟 ${sec}秒`)
      // 一秒后递归
      this.countdownFncs = setTimeout(() => {
        timer--;
        this.countdown(timer);
      }, 1000);
    },
    resetData(query) {
      this.atlasData = null;
      this.mediaID = query.id;
      sessionStorage.setItem("novelDetailsPageId", query.id);
      this.kind = query.kind;
      this.price = query.price ? Number(query.price) : 0;
      this.activeIndex = 0;
      this.isLoading = true;
      this.getMediaInfoList();
      this.getRandomInfoList();
      this.commentList = [];
      this.getCommentList("refresh");
    },
    // 缓存设置级数
    async setActiveIndex() {
      this.directoryShow = false;
      let historyPostInfo = await queryHistoryId(this.atlasData.id);
      this.activeIndex = historyPostInfo.historyIndex || 0;
      this.activeIndex2 = historyPostInfo.historyIndex || 0;
      const element = document.getElementById("anthologyID");
      let targetElement = document.getElementsByClassName(
        `anthologyItem-${this.activeIndex}`
      )[0]; // 选择目标元素
      let rect = targetElement.getBoundingClientRect().left;
      let elementWidth = targetElement.offsetWidth;
      let parentWidth = element.offsetWidth;
      element.scrollLeft = rect + elementWidth / 2 - parentWidth / 2;
      // element.scrollLeft = rect;
    },
    scrolls(index) {
      if (index) {
        this.$nextTick(() => {
          let target = this.$refs[`item-${index}`][0];
          let parent = target.parentNode;
          let parentHalfWidth = parent.offsetWidth / 2;
          let targetHalfWidth = target.offsetWidth / 2;
          parent.scrollLeft =
            target.offsetLeft - parentHalfWidth + targetHalfWidth;
        });
      }
    },
    chapterSort() {
      //章节排序
      this.sorting = !this.sorting;
      this.atlasData.contents2.reverse();
      this.activeIndex2 =
        this.atlasData.contents2.length - 1 - this.activeIndex2;
      // console.log(this.atlasData.contents);
    },
    async onShow(comment) {
      // console.log(comment);
      if (comment.isShow) {
        this.$set(comment, "isShow", false);
      } else {
        this.commentParams.currentHasNext = true; // 初始化当前评论默认有下一页
        this.commentParams.currentFirstItem = comment;
        this.commentParams.currentLoading = true;
        this.secPageNumber = 1;
        await this.getSecondList(comment);
        this.$set(comment, "isShow", true);
      }
    },
    async getSecondList(comment) {
      /**
       * 获取二级评论
       */
      let req = {
        objID: comment.objID,
        cmtId: comment.id,
        fstID: comment.Info[0].id, // 第一条评论的ID
        curTime: new Date().toISOString(),
        pageNumber: this.secPageNumber,
        pageSize: this.secPageSize,
      };

      try {
        let res = await this.$Api(queryCommentListlever2, req);
        this.commentParams.currentLoading = false;
        if (res.data.list && res.data.list.length > 0) {
          let commentNo = [comment.Info[0]];
          if (this.secPageNumber == 1) {
            comment.Info = commentNo.concat(res.data.list);
          } else {
            comment.Info = commentNo.concat(comment.Info.concat(res.data.list));
          }
        }
        if (!res.data.hasNext) {
          this.commentParams.currentHasNext = false;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    async getMediaInfoList() {
      let req = {
        mediaID: this.mediaID,
      };
      let res = await this.$Api(queryMediaInfoList, req);
      const { data } = res;
      // console.log(res.data);
      this.isLoading = false;

      if (res && res.code == 200) {
        this.atlasData = data;
        // console.log(this.atlasData, "---------");
        this.atlasData.contents2 = JSON.parse(
          JSON.stringify(this.atlasData.contents)
        );
        this.msgTotle = data.countComment || 0;
        let historyPostInfo = await queryHistoryId(this.atlasData.id);
        if (historyPostInfo) {
          this.maxEpisode =
            this.$store.state.reader.maxEpisodes[this.atlasData.id];
          this.scrolls(this.maxEpisode);
          this.activeIndex = historyPostInfo.historyIndex || 0;
          this.activeIndex2 = historyPostInfo.historyIndex || 0;
        }
        setSessionItem("currentWorks", JSON.stringify(data));
        this.$store.commit("video/SET_CURRENT_WORKS", data);
      }
    },
    async getRandomInfoList() {
      let req = {
        kind: this.kind,
        pageNumber: 1,
        pageSize: 6,
      };
      let res = await this.$Api(queryRandomInfoList, req);
      if (res && res.code == 200) {
        this.randomList = res.data.MediaList;
      }
    },
    anthology(item, index, type) {
      let _this = this;

      //vip
      if (item.listenPermission == 0 && !this.atlasData.hasPaid) {
        // console.log(_this.$store.getters.isVip);
        if (!_this.$store.getters.isVip) {
          _this.$bus.$emit("vipPopup", {
            state: 1,
            videoType: _this.kind,
            id: _this.kind == 4 ? item.id : this.atlasData.id,
            closeBtn: () => {
              _this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              this.atlasData.hasPaid = true;
              this.$bus.$emit("closeVipPopup");
              // console.log(123123)
            },
          });
          return;
        }
      }
      //金豆收费
      if (item.listenPermission == 1 && !this.atlasData.hasPaid) {
        // console.log(this.atlasData.id, "---------------")
        if (!item.hasPaid) {
          _this.$bus.$emit("vipPopup", {
            id: _this.kind == 4 ? item.mediaID : this.atlasData.id,
            // id: this.atlasData.id,
            state: 2,
            goldenNum: this.price,
            videoType: _this.kind,
            closeBtn: () => {
              _this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              this.atlasData.hasPaid = true;
              this.$bus.$emit("closeVipPopup");
            },
          });
          return;
        }
      }
      console.log(this.atlasData, "+++++++++++++++++", index);
      if (_this.kind == 1) {
        _this.activeIndex = index;
        _this.activeIndex2 = index;
        console.log(_this.activeIndex2);
        this.atlasData.historyIndex = this.activeIndex;
        addVideoHistory(this.atlasData);
        this.$router.push({
          path: "/comicReader",
          query: {
            itemIndex: index,
          },
        });
      } else if (_this.kind == 4) {
        _this.activeIndex = index;
        _this.activeIndex2 = index;
        this.atlasData.historyIndex = this.activeIndex;
        addVideoHistory(this.atlasData);
        _this.$router.push({
          path: "/reader",
          query: {
            itemIndex: index,
          },
        });
      }
    },
    onLoad() {
      this.loading = true;
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
    },
    async collectionWorks() {
      /**
       * 收藏作品
       */

      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(doCollect, req);

      if (res.code == 200) {
        this.atlasData.hasCollected = true;
        this.$toast({
          message: "收藏成功",
          position: "top",
        });
      }
    },
    async cancelCollectionWorks() {
      /**
       * 取消收藏
       */
      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(douUcollect, req);
      if (res.code == 200) {
        this.atlasData.hasCollected = false;
        this.$toast({
          message: "取消收藏",
          position: "top",
        });
      }
    },
    // showInput() {
    //     console.log(123123)
    //     this.placeholder = `我怀疑你也想开车`;
    //     this.cid = null;
    //     this.currMsgItem = null;

    //     this.level = 1;
    //     this.isShowInput = true;
    //     this.$nextTick(() => {
    //         if (this.$refs.newInput) {
    //             this.$refs.newInput.focus()
    //         }
    //     })
    // },
    async getCommentList(type) {
      /**
       * 评论列表
       */
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        curTime: new Date().toISOString(),
        objID: this.mediaID,
      };
      let res = await this.$Api(queryCommentList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      if (res && res.code == 200) {
        if (res.data.list) {
          if (type == "refresh") {
            this.commentList = res.data.list;
          } else {
            this.commentList = this.commentList.concat(res.data.list);
          }
        }
        if (this.pageNumber === 1 && this.commentList.length === 0) {
          this.isNoData = true;
        }
        if (
          !res.data.hasNext ||
          (res.data.list && res.data.list.length < req.pageSize)
        ) {
          this.finished = true;
        }
      }
    },
    jumpUserPage(item) {
      this.$router.replace({
        path: "/userHomePage",
        query: {
          uid: item.userID,
        },
      });
      this.$store.commit("user/SET_COMMENTPOP", {
        show: false,
        info: {},
      });
      if (this.$route.path == "/userHomePage") {
        this.reload();
      }
    },
    showInput() {
      let _this = this;

      /**
       * 加入了判断是否VIP
       */
      if (this.userInfo.isVip && this.userInfo.vipLevel) {
        this.level = 1;
        this.isShowInput = true;
        this.$nextTick(() => {
          if (this.$refs.newInput) {
            this.$refs.newInput.focus();
          }
        });
      } else {
        _this.$bus.$emit("vipPopup", {
          state: 1,
          closeBtn: () => {
            _this.$bus.$emit("closeVipPopup");
          },
        });
      }
    },
    // showInput() {
    //   this.placeholder = `我怀疑你也想开车`;
    //   this.cid = null;
    //   this.currMsgItem = null;

    //   this.level = 1;
    //   this.isShowInput = true;
    //   this.$nextTick(() => {
    //     if (this.$refs.newInput) {
    //       this.$refs.newInput.focus();
    //     }
    //   });
    // },
    closeInput() {
      this.cid = undefined;
      this.userId = undefined;
      this.msgVal = "";
      this.level = 1;
    },
    // 发送时阻止ios默认事件
    async handleMousedown(event) {
      event.preventDefault();
      this.$refs.commentInputBoxRef.blur();
      await this.sendMsg();
    },
    async sendMsg() {
      if (!this.commentParams.content) {
        this.$toast("请输入评论");
        return;
      }
      let req = {};
      if (this.commentParams.level == 1) {
        // 一级评论参数
        req = {
          objID: this.mediaID,
          level: 1,
          content: this.commentParams.content,
          cmtType: "media",
          cid: this.cid,
        };
      } else {
        // 二级评论参数
        if (this.commentParams.toUserID) {
          req = {
            objID: this.mediaID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
            rid: this.commentParams.rid,
            toUserID: this.commentParams.toUserID,
            cmtType: "media",
          };
        } else {
          req = {
            objID: this.mediaID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
            cmtType: "media",
          };
        }
      }
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(sendV2Comment, req);
        this.$store.commit("app/SET_LOADING", false);
        if (res.code == 200) {
          this.commentParams.content = ""; // 评论输入框内容清空
          // this.lfCount++; // 评论+1
          // if (this.commentParams.level == 1) {
          //     this.isNoData = false;
          //     this.commentList.unshift(res.data);
          // } else if (this.commentParams.level == 2) {
          //     this.commentList = this.commentList.map((item) => {
          //         if (item.id == this.commentParams.currentFirstItem.id) {
          //             item.isShow = true;
          //             item.Info.unshift(res.data);
          //         }
          //         return item;
          //     });
          // }
          this.clearReply();
          this.$toast("评论成功，等待审核");
        } else {
          this.$toast(res.tip);
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("评论失败，请稍后再试");
      }
    },
    clearReply() {
      this.commentParams.currentFirstItem = {};
      this.commentParams.objID = "";
      this.commentParams.cid = "";
      this.commentParams.userID = "";
      this.commentParams.level = 1;
      this.placeholder = `我怀疑你也想开车`;
    },
    // 回复-显示输入框
    replyMsg(item) {
      this.placeholder = `回复 @${item.userName}`;
      this.cid = item.id;
      this.userId = item.userId;
      this.level = 2;
      this.isShowInput = true;
      this.currMsgItem = item;
      this.$nextTick(() => {
        if (this.$refs.newInput) {
          this.$refs.newInput.focus();
        }
      });
    },
    async loadMoreSubComment(comment) {
      /**
       * 获取更多二级评论
       * @type {boolean}
       */
      this.commentParams.currentLoading = true;
      this.secPageNumber++;
      await this.getSecondList(comment);
    },
    reply(item, parentItem) {
      /**
       * 二级评论输入框显示事件
       */
      if (parentItem != null) {
        this.commentParams.currentFirstItem = parentItem;
        this.commentParams.rid = item.id;
        this.commentParams.toUserID = item.userID;
        this.commentParams.cid = parentItem.id;
      } else {
        this.commentParams.currentFirstItem = item;
        this.commentParams.rid = "";
        this.commentParams.toUserID = "";
        this.commentParams.cid = item.id;
      }
      this.commentParams.objID = item.objID;
      this.commentParams.userID = item.userID;
      this.commentParams.level = 2;
      this.placeholder = `回复@${item.userName}`;
      this.$refs.commentInputBoxRef.focus();
    },
    tagJump(index, itemTag) {
      let tagInfo = {
        id: this.atlasData.tags[index],
        name: itemTag,
      };
      // console.log(this.$route.path)
      if (this.$route.path == "/tagList") {
        this.$router.replace({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: this.atlasData.kind,
            t: Date.now(),
          },
        });
      } else {
        this.$router.push({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: this.atlasData.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    jumpDetails(item) {
      this.$router.push({
        path: "/commentDetails",
        query: {
          msgInfo: this.encodeBase64(encodeURI(JSON.stringify(item))),
        },
      });
      // this.commentClose();
    },
    // 点赞
    async likeComment(item) {
      let req = {
        objID: item.id,
        type: "comment",
      };
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(
          item.isLike ? thumbsDownComment : thumbsUpComment,
          req
        );
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          if (item.isLike) {
            item.likeCount = item.likeCount - 1 || 0;
          } else {
            item.likeCount = item.likeCount + 1;
          }
          item.isLike = !item.isLike;

          Toast(item.isLike ? "点赞成功" : "取消点赞");
        } else {
          Toast(res.tip || "操作失败，请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("操作失败，请稍后再试");
      }
    },
  },

  // destroyed() {
  //   this.atlasData.historyIndex = this.activeIndex;
  //   addVideoHistory(this.atlasData);
  // },
};
</script>

<style lang="scss" scoped>
.defaultInput {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  z-index: 2004;
  padding: 0 12px 34px;
  box-sizing: border-box;

  /deep/ .van-field__body {
    background: rgba(238, 238, 238, 1);
    padding: 6px 17px;
    border-radius: 16px;
  }

  .inputBox {
    display: flex;
    align-items: center;

    .sendBtn {
      background: url("../../assets/png/sendMsg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .comments {
    height: 59px;
    display: flex;
    justify-content: space-between;
    padding: 19px 24px;
    color: rgb(153, 153, 153);
    //font-weight: bold;
    font-size: 15px;

    img {
      width: 18.2px;
      height: 18.2px;
    }
  }

  .placeholder {
    height: 34px;
  }
}
.novelDetailsPage {
  height: calc(100% - 1px);
  background: #ffffff;
  overflow-x: hidden;
  .tabBars {
    background: #ffffff;
    position: fixed;
    bottom: 45px;
    height: 60px;
    width: 100%;
    z-index: 999;
    .comments {
      height: 32px;
      display: flex;
      justify-content: space-between;
      padding: 19px 24px;
      color: rgb(153, 153, 153);
      font-size: 15px;
      .Inputs {
        border-radius: 16px;
        background: #eeeeee;
        /deep/ .van-field__control {
          margin-top: -6px !important;
        }
      }
      .sendBtn {
        display: flex;
        align-items: center;
        img {
          margin-left: 12px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .tabBar {
    background: rgb(240, 240, 244);
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    z-index: 2;

    .comments {
      height: 59px;
      display: flex;
      justify-content: space-between;
      padding: 19px 24px;
      color: rgb(153, 153, 153);
      //font-weight: bold;
      font-size: 15px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .placeholder {
      height: 34px;
    }
  }

  .content {
    height: 100%;
    overflow-y: auto;
    position: relative;
  }

  .headBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 323px;
    /deep/ img {
      object-position: top;
    }
    //-webkit-filter: blur(2px);
    //filter: blur(2px);
  }
  .headBg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 1) 93%
    );
  }

  .head {
    position: relative;
    height: 128px;
    // background: rgba(19, 15, 50, 0.86);
    width: 100%;
    // position: absolute;
    // top: 0;

    .headBar {
      display: flex;
      justify-content: space-between;
      height: 44px;
      align-items: center;
      z-index: 999;
      margin-top: 2px;
      .leftIcon {
        padding: 0 16px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .btnGroup {
      // justify-content: flex-end;
      // margin-top: 78px;
      //padding-right: 12px;
      // width: 100%;

      .collectionBtn {
        margin-right: 12px;

        img {
          width: 60px;
          height: 24px;
        }
      }
    }
  }

  .worksInfo {
    height: 301px;
    // background: #F1F1F1;
    // margin-top: -5px;
    position: relative;

    .contentInfo {
      width: 100%;
      position: absolute;
      top: 45px;
      //left: 16px;
      // padding-right: 16px;
      //display: grid;
      //grid-template-columns: repeat(2, 1fr);
      //gap: 12px;
    }
    .previewImg {
      width: 128px;
      height: 170px;
      border-radius: 8px;
      // /deep/ .van-image {
      //     border-radius: 6px;
      // }
      /deep/ .van-image__img {
        border-radius: 8px;
      }
    }

    .worksContent {
      //width: 215px;
      padding-top: 12px;

      .worksTitle {
        padding: 0 16px;
        font-size: 20px;
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }

      .worksTag {
        padding-left: 16px;
        margin: 8px 0 6px 0;
        font-size: 14px;
        color: rgba(0, 255, 224, 1);
        display: flex;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;

        span {
          margin-top: 4px;
          margin-right: 12px;
          border: 1px solid rgba(0, 255, 224, 1);
          border-radius: 16px;
          font-size: 12px;
          font-weight: 500;
          padding: 4px 10px;
          line-height: 12.8px;
        }
      }
      .dataNum {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 11px 0 18px 16px;
        div {
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          color: #ffffff;
          border-right: 1px solid #ffffff;
          display: inline-block;
          padding: 0 12px;
        }
        div:first-child {
          padding-left: 0;
        }
        div:last-child {
          border-right: none;
        }
      }
      .newVip {
        width: 100%;
        height: 38px;
        background: linear-gradient(0deg, #262012 0%, #080703 100%);
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: rgba(254, 236, 186, 1);
        .newUserText {
          font-size: 12px;
          font-weight: 500;
        }

        .Diamond {
          padding-left: 12px;

          img {
            display: inline-block;
            vertical-align: middle;
            width: 62.14px;
            height: 38px;
          }
        }

        .immediately {
          padding-right: 8px;

          img {
            display: inline-block;
            vertical-align: middle;
            width: 78px;
            height: 24px;
          }
        }
      }
    }

    .worksDetails {
      padding: 0 16px;
      width: 100%;
      margin-top: 14px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);

      /deep/ .overEllipsis {
        font-size: 12px;
      }

      /deep/ .text-btn {
        font-size: 14px;
        margin: 10px 0 0 0;
        color: #999999;
      }

      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
    }

    .worksFullBtn {
      padding: 0 16px;
      margin-top: 12px;
      color: rgb(153, 153, 153);
      font-size: 14px;
    }
    .showAll {
      margin-bottom: 9px;
    }
  }

  .directory {
    height: 90px;
    //margin-top: 22px;

    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(242, 242, 242, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    .directory-left {
      //position: absolute;
      //left: 16px;
      //top: 50%;
      //transform: translateY(-50%);
      padding: 0 16px;
      width: 60px;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      color: rgba(51, 51, 51, 1);
      .catalogue {
        width: 18px;
        height: 18px;
      }
    }
    .directoryBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 25px;
      padding: 0 12px;
      color: rgb(0, 0, 0);

      .directoryBarTitle {
        font-size: 18px;
      }

      .directoryBtn {
        font-size: 15px;

        img {
          width: 6.9px;
          height: 8.6px;
          margin-left: 8.1px;
        }
      }
    }
    .anthology {
      display: flex;
      //margin-top: 12px;
      //justify-content: center;
      align-items: center;
      // overflow-x: auto;
      overflow-y: hidden;

      .anthologyItem:first-child {
        margin-left: 0;
      }
      .anthologyItem {
        //-webkit-flex: 1 0 auto;
        width: 48px;
        height: 48px;
        background: #ffffff;
        margin-left: 12px;
        font-weight: bold;
        border-radius: 8px;

        .activeItem {
          background: rgba(255, 97, 62, 0.1);
          border-radius: 8px;
        }

        .vipLabel {
          display: flex;
          justify-content: flex-end;
          width: 48px;

          span {
            background-image: linear-gradient(to right, #f3cd64, #fde39c);
            font-size: 10px;
            width: 24px;
            height: 12px;
            color: #663d00;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
          }
        }

        .goldenLabel {
          display: flex;
          justify-content: flex-end;
          width: 48px;

          span {
            background-image: linear-gradient(
              to right,
              rgb(255, 57, 87),
              rgb(255, 89, 34)
            );
            font-size: 6px;
            width: 24px;
            height: 12px;
            color: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
          }
        }

        .freeLabel {
          display: flex;
          justify-content: flex-end;
          width: 48px;

          span {
            background-image: linear-gradient(
              to right,
              rgb(87, 139, 255),
              rgb(51, 255, 227)
            );
            font-size: 6px;
            width: 24px;
            height: 12px;
            color: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
          }
        }

        .activePlay {
          position: relative;
          top: -22px;
          left: 7px;

          img {
            width: 13px;
            height: 8px;
          }
        }

        .anthologyTxt {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -12px;
        }
      }
    }

    .startReading {
      //position: absolute;
      //right: 16px;
      //top: 50%;
      //transform: translateY(-50%);
      padding: 0 16px;
      .startReadingBtn {
        width: 104px;
        height: 46px;
        background: rgba(251, 209, 61, 1);
        border-radius: 24px;
        color: rgba(71, 0, 0, 1);
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }
    }
  }
  .lines {
    width: 92%;
    height: 1px;
    margin: 26px 16px 0;
    background: #e6e6e6;
  }
  .contentBar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    //padding: 0 12px;

    /deep/ .van-tabs__nav--line {
      width: 143px !important;
      height: 40px !important;
      display: flex;
      align-items: center;
    }

    /deep/ .van-tabs__line {
      //bottom: 23px;
    }

    /deep/ .van-tabs__wrap {
      background: #ffffff;
      //border-bottom: solid 1px rgba(230, 230, 230, 0.63);
      .van-tabs__line {
        background: transparent;
        background-image: url("~@/assets/png/novelDetailTags.png") !important;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 13px !important;
        height: 5px !important;
        bottom: 20px;
      }
      .van-tabs__nav--line {
        //height: 100%;
        //margin-bottom: 30px;
      }
    }

    /deep/ .van-tab--active {
      font-size: 15px;
    }

    /deep/ .van-tab {
      font-size: 15px;
      // width: 51px;
    }

    /deep/ .van-tab__pane {
      // height: calc(100vh - 390px);
      height: calc(100vh - 50px);
    }

    /deep/ .van-tab__text {
      overflow: visible;
    }

    .tabItemTitle {
      // width: 100px;
      // position: relative;

      .comTotle {
        position: absolute;
        left: calc(100% + 6px);
        top: 0;
        font-size: 12px;
        font-weight: 400;
        color: rgba(252, 65, 98, 1);
      }
    }

    .tabs {
      //width: 114px;
      width: 100%;
      height: 100%;

      .tabContent {
        //height: calc(100vh - 211px - 51px - 60px);
        .videoContent {
          //height: calc(100vh - 211px - 51px - 92px);
          background: rgba(255, 255, 255, 1);

          .recommendVideo {
            //margin-top: 36px;
            padding: 0 12px;

            .recommendTitle {
              font-size: 18px;
              color: rgb(0, 0, 0);
              font-weight: bold;
              margin-bottom: 9px;
            }
          }

          .placeholder {
            height: 100px;
          }
        }

        .commentsContent {
          background: #ffffff;
          //height: calc(100vh - 211px - 51px - 92px);
          color: rgb(0, 0, 0);
          //height: 100%;
          //overflow-y: auto;

          .commentsHead {
            font-size: 18px;
            font-weight: bold;
            margin-top: 18px;
            padding-left: 12px;
          }

          .commentsContentList {
            padding: 18px 12px;

            .commentBox {
              margin-bottom: 26px;
              margin-top: 15px;
              border-bottom: solid rgb(229, 229, 229) 1px;

              .commentInfo {
                display: flex;
                width: 100%;

                .godMsg {
                  position: absolute;
                  right: 55.8px;
                  width: 60.2px;
                  height: 60.2px;
                  z-index: 10;
                }

                .avatar {
                  height: 40px;
                  width: 40px;
                  border-radius: 50%;
                  margin-right: 12px;
                  flex-shrink: 0;
                }

                .info {
                  width: calc(100vw - 100px);
                  flex-shrink: 0;
                  color: rgb(51, 51, 51);

                  .name {
                    color: rgb(51, 51, 51);
                    font-size: 12px;

                    span {
                      margin-right: 4px;
                    }

                    svg {
                      width: 10px;
                      height: 10px;
                    }
                  }

                  .msgContent {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .zanBox {
                      position: absolute;
                      right: 0;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      z-index: 20;

                      .zanIcon {
                        height: 22px;
                        width: 22px;
                        margin-bottom: 5px;
                      }

                      .zanNumber {
                        font-size: 12px;
                      }
                    }
                  }

                  .text {
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    text-overflow: ellipsis;

                    font-size: 14px;
                    //margin: 4px 0 7px;
                    margin-top: 5px;
                    color: rgb(51, 51, 51);
                  }

                  .address {
                    //font-size: 10px;
                    //color: rgb(153, 153, 153);
                    margin-top: 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .createdAt {
                      font-size: 12px;
                      color: rgb(153, 153, 153);
                    }

                    //.btn {
                    //  font-size: 10px;
                    //  color: rgb(255, 255, 255);
                    //  margin-left: 6px;
                    //}

                    .btnBox {
                      display: flex;
                      align-items: center;

                      .icon {
                        width: 20px;
                        height: 20px;
                      }

                      .community_info {
                        width: 20px;
                        height: 20px;
                      }

                      .like {
                        margin-left: 18px;
                      }
                    }
                  }
                }

                .childInfo {
                  width: calc(100vw - 150px);
                  flex-shrink: 0;
                  color: rgb(102, 102, 102);

                  .name {
                    color: rgb(153, 153, 153);
                    font-size: 12px;
                    display: flex;
                    align-items: center;

                    span {
                      margin-right: 4px;
                    }

                    svg {
                      width: 10px;
                      height: 10px;
                    }

                    .rightCommIcon {
                      width: 14px;
                      height: 14px;
                      margin-left: 8px;
                      margin-right: 8px;
                    }
                  }

                  .msgContent {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .zanBox {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      z-index: 20;

                      .zanIcon {
                        height: 22px;
                        width: 22px;
                        margin-bottom: 5px;
                      }

                      .zanNumber {
                        font-size: 12px;
                      }
                    }
                  }

                  .text {
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    text-overflow: ellipsis;

                    font-size: 14px;
                    //margin: 4px 0 7px;
                    margin-top: 5px;
                    color: rgb(51, 51, 51);
                  }

                  .address {
                    font-size: 10px;
                    color: rgb(153, 153, 153);
                    margin-top: 8px;

                    .btn {
                      font-size: 10px;
                      color: rgb(255, 255, 255);
                      margin-left: 6px;
                    }
                  }
                }
              }

              .expand-box {
                font-size: 12px;
                color: rgb(153, 153, 153);
                font-weight: 300;
                margin: 8px 0;
                display: flex;
                align-items: center;

                > svg {
                  height: 12px;
                  width: 12px;
                  margin-left: 5px;
                }
              }

              // 旋转180度
              .rotate-target {
                transform: rotate(180deg);
                transition: transform 0.3s;
              }

              // 旋转0度
              .rotate-burden-target {
                transform: rotate(0deg);
                transition: transform 0.3s;
              }
            }
          }
        }
      }
    }

    .rerouteBox {
      position: absolute;
      right: 12px;
      padding-top: 12px;
      display: flex;
      align-items: center;
      display: block;
      vertical-align: middle;
    }

    .collectBtn {
      position: absolute;
      right: 12px;
      padding-top: 12px;
      //background: rgb(27,22,76);

      img {
        width: 72px;
        height: 26px;
      }
    }
  }

  .introductionContent {
    padding: 0 12px;

    .introductionBar {
      margin-top: 18px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 14px;
        color: #333333;
        font-weight: 400;
      }

      .btn {
        display: flex;
        align-items: center;

        img {
          width: 14.8px;
          height: 14.8px;
        }
      }
    }

    .introductionTitle {
      font-size: 16px;
      color: rgb(0, 0, 0);
      font-weight: 500;
      margin-top: 16px;
    }

    .introductionTag {
      margin: 8px 0 16px 0;
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;

      span {
        margin-top: 8px;
        margin-right: 12px;
        background: rgba(148, 214, 218, 0.3);
        border-radius: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 4px 10px;
        line-height: 16.8px;
      }
    }

    .introductionDetails {
      color: rgb(0, 0, 0);
      font-size: 12px;
    }
  }

  .directoryContent {
    .directoryBar {
      padding: 0 12px;
      margin-top: 18px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 18px;
        color: rgb(0, 0, 0);
        font-weight: bold;
      }

      .btn {
        display: flex;
        align-items: center;

        img {
          width: 14.8px;
          height: 14.8px;
        }
      }
    }

    .anthologyContent {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;

      .anthologyItem {
        width: 60px;
        height: 60px;
        border-radius: 3px;
        color: rgb(0, 0, 0);
        background: rgb(240, 240, 244);
        margin-left: 12px;
        margin-bottom: 19px;
        font-weight: bold;

        .activeItem {
          background: rgba(255, 97, 62, 0.1);
          color: #333333;
          border-radius: 6px;
        }

        .vipLabel {
          display: flex;
          justify-content: flex-end;
          width: 60px;

          span {
            background-image: linear-gradient(to right, #f3cd64, #fde39c);
            font-size: 10px;
            width: 24px;
            height: 12px;
            color: #663d00;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
          }
        }

        .goldenLabel {
          display: flex;
          justify-content: flex-end;
          width: 60px;

          span {
            background-image: linear-gradient(
              to right,
              rgb(255, 57, 87),
              rgb(255, 89, 34)
            );
            font-size: 6px;
            width: 24px;
            height: 12px;
            color: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
          }
        }

        .freeLabel {
          display: flex;
          justify-content: flex-end;
          width: 60px;

          span {
            background-image: linear-gradient(
              to right,
              rgb(87, 139, 255),
              rgb(51, 255, 227)
            );
            font-size: 6px;
            width: 24px;
            height: 12px;
            color: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
          }
        }

        .activePlay {
          position: relative;
          top: -22px;
          left: 7px;

          img {
            width: 13px;
            height: 8px;
          }
        }

        .anthologyTxt {
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -12px;
        }
      }
    }
  }

  .separate {
    background: rgb(230, 230, 230);
    height: 1px;
  }

  .comments {
    .commentsContent {
      //height: calc(100vh - 211px - 51px - 92px);
      color: rgb(0, 0, 0);
      //height: 100%;
      //overflow-y: auto;

      .commentsHead {
        font-size: 16px;
        font-weight: 500;
        margin-top: 18px;
        padding-left: 12px;
        .comTotle {
          padding-left: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #94d6da;
        }
      }

      .commentsContentList {
        padding: 18px 12px;
        .commentBox {
          margin-bottom: 26px;
          margin-top: 15px;
          border-bottom: solid rgb(229, 229, 229) 1px;

          .commentInfo {
            display: flex;
            width: 100%;

            .godMsg {
              position: absolute;
              right: 55.8px;
              width: 60.2px;
              height: 60.2px;
              z-index: 10;
            }

            .avatar {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              margin-right: 12px;
              flex-shrink: 0;
            }

            .info {
              width: calc(100vw - 100px);
              flex-shrink: 0;
              color: rgb(51, 51, 51);

              .name {
                color: rgb(51, 51, 51);
                font-size: 12px;

                span {
                  margin-right: 4px;
                }

                svg {
                  width: 10px;
                  height: 10px;
                }
              }

              .msgContent {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .zanBox {
                  position: absolute;
                  right: 0;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  z-index: 20;

                  .zanIcon {
                    height: 22px;
                    width: 22px;
                    margin-bottom: 5px;
                  }

                  .zanNumber {
                    font-size: 12px;
                  }
                }
              }

              .text {
                //width: 300px;
                //text-overflow: ellipsis;
                //word-wrap: break-word;
                //white-space: pre-wrap;

                //width: 100%;
                //overflow: hidden;
                white-space: pre-wrap;
                word-wrap: break-word;
                text-overflow: ellipsis;

                font-size: 14px;
                //margin: 4px 0 7px;
                margin-top: 5px;
                color: rgb(51, 51, 51);
              }

              .address {
                //font-size: 10px;
                //color: rgb(153, 153, 153);
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .createdAt {
                  font-size: 12px;
                  color: rgb(153, 153, 153);
                }

                //.btn {
                //  font-size: 10px;
                //  color: rgb(255, 255, 255);
                //  margin-left: 6px;
                //}

                .btnBox {
                  display: flex;
                  align-items: center;

                  .icon {
                    width: 20px;
                    height: 20px;
                  }

                  .community_info {
                    width: 14px;
                    height: 14px;
                  }

                  .like {
                    margin-left: 18px;
                  }
                }
              }
            }

            .childInfo {
              width: calc(100vw - 150px);
              flex-shrink: 0;
              color: rgb(102, 102, 102);

              .name {
                color: rgb(153, 153, 153);
                font-size: 12px;
                display: flex;
                align-items: center;

                span {
                  margin-right: 4px;
                }

                svg {
                  width: 10px;
                  height: 10px;
                }

                .rightCommIcon {
                  width: 14px;
                  height: 14px;
                  margin-left: 8px;
                  margin-right: 8px;
                }
              }

              .msgContent {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .zanBox {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  z-index: 20;

                  .zanIcon {
                    height: 22px;
                    width: 22px;
                    margin-bottom: 5px;
                  }

                  .zanNumber {
                    font-size: 12px;
                  }
                }
              }

              .text {
                //width: 300px;
                //text-overflow: ellipsis;
                //word-wrap: break-word;
                //white-space: pre-wrap;

                //width: 100%;
                //overflow: hidden;
                white-space: pre-wrap;
                word-wrap: break-word;
                text-overflow: ellipsis;

                font-size: 14px;
                //margin: 4px 0 7px;
                margin-top: 5px;
                color: rgb(51, 51, 51);
              }

              .address {
                font-size: 10px;
                color: rgb(153, 153, 153);
                margin-top: 8px;

                .btn {
                  font-size: 10px;
                  color: rgb(255, 255, 255);
                  margin-left: 6px;
                }
              }
            }
          }

          .expand-box {
            font-size: 12px;
            color: rgb(153, 153, 153);
            font-weight: 300;
            margin: 8px 0;
            display: flex;
            align-items: center;

            > svg {
              height: 12px;
              width: 12px;
              margin-left: 5px;
            }
          }

          // 旋转180度
          .rotate-target {
            transform: rotate(180deg);
            transition: transform 0.3s;
          }

          // 旋转0度
          .rotate-burden-target {
            transform: rotate(0deg);
            transition: transform 0.3s;
          }
        }
      }
    }
  }

  .inputPop {
    .inputBox {
      height: 59px;
      padding: 0 12px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .newInput {
        width: 273px;
        height: 31px;
        border-radius: 31px;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: #e6e6e6;

        /deep/ .van-field__control {
          font-size: 12px;
          color: #000;
        }

        ::placeholder {
          color: #999;
        }
      }

      .sendBtn {
        width: 66px;
        height: 33px;
        border-radius: 33px;
        font-size: 15px;
        color: #fff;
        background: #757494;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .activeBtn {
        color: #fff;
        background: #7145e7;
      }
    }
  }

  .nodata {
    height: calc(100vh - 103px);
    // height: calc(100% - 92px);
    width: 100%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
